export function createVideoEl(url=null) {
    var videoEl = document.createElement('video');    
    videoEl.style.display = 'none';
    videoEl.setAttribute('loop', true);
    videoEl.setAttribute('width', 2000);
    videoEl.setAttribute('height', 2000);
    //videoEl.muted = true;
    videoEl.crossOrigin = "anonymous";
    if(url) {
        var source = document.createElement('source');
        source.src = url;
        source.type = 'video/mp4';
        videoEl.appendChild(source);
    }
    document.body.appendChild(videoEl);
    return videoEl;
}