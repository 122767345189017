import {addCommonMethods} from "../addCommonMethods";

export async function initWeather(id, toolVariant, initialParams, initialGroupParams, canvas, name = null) {
    let additionalParams = {
        fontSize: 36,
        fontStyle: 'normal',
        fontWeight: 'normal',
        textAlign: 'left',
        text: 'MM DD YYYY h:mm:ss',
        fill: '#111111',
    };
    let groupParams = {
        id,
        ...initialGroupParams,
    }
    let weatherParams = {
        id,
        objectType: 'weather',
        pattern: 'MM DD YYYY h:mm:ss',
        ...additionalParams,
        ...{
            top: 0,
            left: 0,
            width: initialGroupParams.width,
            height: initialGroupParams.height
        },
        ...initialParams
    }

    let weatherbox = new fabric.IText('', weatherParams);

    weatherbox.set({
        left: groupParams.left,
        top: groupParams.top,
        editable: false,
        originX: groupParams.originX,
        originY: groupParams.originY,
    });

    addCommonMethods(weatherbox, canvas);

    weatherbox.scaleX = groupParams.width/weatherbox.width;
    weatherbox.scaleY = groupParams.height/weatherbox.height; 
    
    return {
        id,
        name: name ? name : 'Weather',
        visible: true,
        selectable: true,
        groupParams,
        objectParams: weatherParams,
        objectType: 'weather',
        object: weatherbox,
        effects: [],
        animations: []
    };
}
