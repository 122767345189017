<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import Section from "@/components/layout/Section";
  import Checkbox from "../custom/Checkbox";
  import NumberInput from "../custom/NumberInput";
  import Colorpicker from "../custom/Colorpicker";
  import AlignControls from "../common/AlignControls";
  import SizeControls from "../common/SizeControls";
  import FileInput from "@/components/controls/custom/FileInput";

  export default {
    computed: {
      ...mapGetters('scenes', ['params','currentObject']),
      ...mapState('scenes', ['fabricObject']),
    },
    components: {FileInput, SizeControls, AlignControls, Colorpicker, NumberInput, Checkbox, Section},
    methods: {
      async setSource(e) {
        let source = e.target.value;
        if(this.fabricObject)
          await this.fabricObject.initPicture(source ? source : '');
        this.onChange('url');
      },
      setAutoSize(state) {
        if (state) {
          if (this.fabricObject.url === '') {
            return;
          }
          if(this.fabricObject.pictureWidth) {
            this.fabricObject.width = this.fabricObject.pictureWidth;
            this.fabricObject.height = this.fabricObject.pictureHeight;
            this.fabricObject.scaleX = 1;
            this.fabricObject.scaleY = 1;
          }
          this.fabricObject.autoSize = !this.fabricObject.autoSize;
          this.onChangeAlign();
        }
      },
      onChange(e) {
        this.$emit('change', e);
      },
      onChangeAlign() {
        this.setAlign(this.fabricObject.textAlign);
        this.setVerticalAlign(this.fabricObject.originY);
        this.onChange();
      },
      setAlign(align) {
        this.fabricObject.textAlign = align;
        this.onChange('textAlign');
      },
      setVerticalAlign(align) {
        this.onChange('originY');
        this.onChange('top');
      },

    }
  }

</script>
<template>
  <div class="picture-controls">

    <Section :isExpanded="true">
      <div slot="title">Picture tools</div>
      <div class="inputs-row">
        <div class="input-block">
          <label class="input-block__title">Source</label>
          <FileInput  :icon="'images/ic-image.svg'" :extensions="['jpg', 'jpeg', 'png', 'gif']" v-model="fabricObject.url" @change="(e) => setSource(e)"  />
        </div>
      </div>
      <Checkbox title="Auto-size" v-model="fabricObject.autoSize" @change="(e) => setAutoSize(e)" />
    </Section>
    <Section>
      <div slot="title">Size & position</div>
      <SizeControls @change="onChange" />
    </Section>
    <Section>
      <div slot="title">Align</div>
      <AlignControls @change="onChangeAlign" />
    </Section>
  </div>
</template>
<style lang="scss">
  .picture-controls {

  }
</style>
