import {addCommonMethods} from "../addCommonMethods";
import {createVideoEl} from "../createVideoEl";

import {fabric} from "@/fabric";

export async function initVideo(id, toolVariant, initialParams, initialGroupParams, canvas, name = null) {

    let additionalParams = {
        url: ''
    };
    let groupParams = {
        id,
        ...initialGroupParams,
    }
    let videoParams = {
        id,
        objectType: 'video',
        ...additionalParams,
        originY: 'top',
        ...{
            top: 0,
            left: 0,
            width: initialGroupParams.width,
            height: initialGroupParams.height
        }
    }

    let videoEl = createVideoEl();

    let video = new fabric.Image(videoEl);
    video.objectType = videoParams.objectType;
    addCommonMethods(video, canvas, videoEl);

    if (initialParams.url) {
       await video.initVideo(initialParams.url);
    }

    let width = video.videoWidth || 400;
    let height = video.videoHeight || 400;
    video.set({
        id,
        objectCaching: false,
        left: groupParams.left,
        top: groupParams.top,
        width: width,
        height: height,
        scaleX: groupParams.width / width,
        scaleY: groupParams.height / height,
        originX: groupParams.originX,
        originY: groupParams.originY,
    });

    return {
        id,
        name: name ? name : 'Video',
        visible: true,
        selectable: true,
        groupParams,
        objectParams: videoParams,
        objectType: 'video',
        object: video,
        effects: [],
        animations: []
    };
}
