import {addCommonMethods} from "../addCommonMethods";

export async function initPicture(id, toolVariant, initialParams, initialGroupParams, canvas, name = null) {

    let additionalParams = {
        url: ''
    };
    let groupParams = {
        id,
        ...initialGroupParams,
    }
    let pictureParams = {
        id,
        objectType: 'picture',
        ...additionalParams,
        originY: 'top',
        ...{
            top: 0,
            left: 0,
            width: initialGroupParams.width,
            height: initialGroupParams.height
        }
    }

    let picture = new fabric.Image();
    picture.objectType = pictureParams.objectType;

    picture.initPicture = (url) => {
        const noPicture = url === 'n';
        if (!url) {
            url = picture.url;
        } else {
            picture.url = url;
        }

        //let fullUrl = `http://flash.webcraft.company/picture?uri=${url}`;
        let fullUrl = `${url}`;
        if (noPicture) {
            fullUrl = '/images/blank.png';
        }
        return new Promise((resolve, reject) => {

            let img = new Image();
            img.onload = () => {
                if(!picture.autoSize) {
                    picture.scaleX = picture.width * picture.scaleX / img.width;
                    picture.scaleY = picture.height * picture.scaleY / img.height;
                } else {
                    picture.scaleX = 1;
                    picture.scaleY = 1;
                }
                picture.pictureWidth = img.width;
                picture.pictureHeight = img.height;
                picture.setSrc(fullUrl, () => {
                    if (noPicture) {
                        setTimeout(() => {
                            picture.url = '';
                        }, 1)
                    }
                     resolve();
                }, {
                     crossOrigin: 'anonymous'
                });
            }
            img.src = fullUrl;
        })
    }
    if (initialParams.url) {
       await picture.initPicture(initialParams.url);
    }
    let width = picture.pictureWidth || 400;
    let height = picture.pictureHeight || 400;

    picture.set({
        id,
        left: groupParams.left,
        top: groupParams.top,
        width: width,
        height: height,
        scaleX: groupParams.width / width,
        scaleY: groupParams.height / height,
        originX: groupParams.originX,
        originY: groupParams.originY,
    });

    addCommonMethods(picture, canvas);
    
    return {
        id,
        name: name ? name : 'Picture',
        visible: true,
        selectable: true,
        groupParams,
        objectParams: pictureParams,
        objectType: 'picture',
        object: picture,
        effects: [],
        animations: []
    };
}
