import { render, staticRenderFns } from "./Colorpicker.vue?vue&type=template&id=2aa6a4a0&"
import script from "./Colorpicker.vue?vue&type=script&lang=js&"
export * from "./Colorpicker.vue?vue&type=script&lang=js&"
import style0 from "./Colorpicker.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports